import { getRequest, postRequest,urlTitle } from "@/utils/request";
import qs from "qs";
window.qs = qs;
// 试验项管理
export const testItemApi = {
  // 试验项分类
  queryTestType: () => {
    return getRequest(`${urlTitle}/operation/experiment/list`);
  },
  // 试验项分类
  updateTestType: (params) => {
    return postRequest(`${urlTitle}/operation/experiment/update`, params);
  },
  // 试验项分类
  createTestType: (params) => {
    return postRequest(`${urlTitle}/operation/experiment/add`, params);
  },
  // 删除试验项
  deleteTestType: (id) => {
    return postRequest(`${urlTitle}/operation/experiment/${id}`);
  },
  // 试验项列表
  queryTestItem: (params) => {
    return getRequest(`${urlTitle}/operation/experiment/item/page/list`, params);
  },
  // 创建试验项
  createTestItem: (params) => {
    return postRequest(`${urlTitle}/operation/experiment/item/add`, params);
  },
  // 修改试验项
  updateTestItem: (params) => {
    return postRequest(`${urlTitle}/operation/experiment/item/update`, params);
  },
  // 删除试验项
  deleteTestItem: (id) => {
    return postRequest(`${urlTitle}/operation/experiment/item/${id}`);
  },
};
